<template>
  <div class="profile-container">
    <div class="profile-container-header">
      <span class="title">我的消息</span>
    </div>
    <div class="profile-container-content">
      <el-table
        :data="tableData"
        style="width: 100%"
        v-loading="alarmLoading"
        @cell-click="cellClick"
      >
        <el-table-column prop="title" label="标题" width="180">
        </el-table-column>
        <el-table-column prop="message" label="正文" width="340">
        </el-table-column>
        <el-table-column prop="publish_time" label="时间" width="280px">
          <template slot-scope="scope">
            <!--            <span>{{ format(item.property) }}</span>-->
            <span>{{ getDate({ timestamp: scope.row.publish_time }) }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="content" label="消息状态">
          <template slot-scope="scope">
            <span>{{ scope.row.status === 2 ? "已读" : "未读" }}</span>
            <!--            <span class="reset-bg">需更换</span>-->
          </template>
        </el-table-column>
        <el-table-column prop="btn" label="删除">
          <template slot-scope="scope">
            <el-button type="text" @click="deleteMessage(scope.row)"
              >删除</el-button
            >
            <!--            <span>{{ scope.row.status === 2 ? "已读" : "未读" }}</span>-->
            <!--            <span class="reset-bg">需更换</span>-->
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import { dateFormat } from "@/utils/week";
import { getDate } from "@/utils";

export default {
  name: "myMessage",
  data() {
    return {
      active: 1,
      tableData: [],
      alarmLoading: false,
      // tableData: [
      //   {
      //     id: "JD123123",
      //     name: "一年级1班",
      //     time: "2021-05-21 16:33:22",
      //     content: "初效滤网剩余1小时",
      //   },
      //   {
      //     id: "JD123124",
      //     name: "一年级2班",
      //     time: "2021-05-21 16:33:22",
      //     content: "初效滤网剩余1小时",
      //   },
      //   {
      //     id: "JD123125",
      //     name: "一年级3班",
      //     time: "2021-05-21 16:33:22",
      //     content: "初效滤网剩余1小时",
      //   },
      //   {
      //     id: "JD123126",
      //     name: "一年级4班",
      //     time: "2021-05-21 16:33:22",
      //     content: "初效滤网剩余1小时",
      //   },
      // ],
    };
  },
  methods: {
    deleteMessage(row) {
      this.$confirm("是否删除该消息？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$http.updateCustomerMessageStatus(row.id, 0).then((res) => {
            console.log(res);
            this.$message({
              message: "删除成功",
              type: "success",
            });
            this.getData();
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    cellClick(row, column) {
      if (column.label !== "删除" && row.status != 2) {
        // console.log("dianji");
        this.$http.updateCustomerMessageStatus(row.id, 2).then((res) => {
          console.log(res);
          this.$message({
            message: "已读",
            type: "success",
          });
          this.getData();
        });
        // this.$router.push({
        //   path: `/assets/${row.id.id}`,
        //   query: { title: row.name },
        // });
      }
    },
    getData() {
      this.alarmLoading = true;
      this.$http
        .findCustomerMessage()
        .then((res) => {
          this.tableData = res.data;
          this.alarmLoading = false;
        })
        .catch(() => {
          this.alarmLoading = false;
        });
    },
    dateFormat,
    getDate,
  },
  mounted() {
    this.getData();
  },
};
</script>

<style scoped lang="scss">
.profile-container {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.05);
  background-color: #fff;
  min-width: 999px;
  .profile-container-header {
    width: 100%;
    display: flex;
    height: 70px;
    padding-left: 40px;
    align-items: center;
    color: #333;
    //span {
    //  width: 90px;
    //  line-height: 50px;
    //  text-align: center;
    //  font-size: 14px;
    //  color: #333;
    //  cursor: pointer;
    //  border-bottom: 1px solid #eaeaea;
    //  &:nth-child(2) {
    //    border-left: 1px solid #eaeaea;
    //    border-right: 1px solid #eaeaea;
    //  }
    //  &:nth-last-child(1) {
    //    flex: 1;
    //  }
    //}
    //.active {
    //  //background-color: #999;
    //  border-bottom: none;
    //  position: relative;
    //  //border-top: 4px solid #01cfa5;
    //  &::before {
    //    content: "";
    //    width: 90px;
    //    height: 4px;
    //    background-color: #01cfa5;
    //    position: absolute;
    //    left: 0;
    //  }
    //}
  }
  .profile-container-content {
    padding-left: 40px;
    //padding-top: 20px;
    display: flex;
    flex-direction: column;
    .reset-bg {
      line-height: 18px;
      display: inline-block;
      font-size: 12px;
      width: 42px;
      height: 18px;
      background-color: #ff6c6c;
      border-radius: 10px;
      color: #fff;
      margin-left: 5px;
      text-align: center;
    }
    .profile-block {
      height: 60px;
      border-bottom: 1px solid #eaeaea;
      font-size: 14px;
      color: #333;
      span {
        &:nth-child(2) {
          margin-left: 30px;
          line-height: 60px;
        }
      }
    }
    ::v-deep .el-table {
      .el-table__row {
        height: 60px;
      }
      .is-leaf {
        background-color: #f4f4f5;
      }
    }
  }
}
</style>
